import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const ProtectedRoute = ({ children }) => {
  // const { user } = useAuth();
  const navigate = useNavigate();
  const [token ,setToken]= useState()

  useEffect(() => {
    const checkUser = async () => {
     console.log("called inside checkUser")
      // Add a delay to wait for the state update
      // await new Promise(resolve => setTimeout(resolve, 0));
      const url = new URL(window.location.href);

      // Get the value of the 'st' parameter
      const stParam = url.searchParams.get("st");

      if(stParam){
        setToken(stParam);
        sessionStorage.setItem("userId",stParam)

      }

      
      const storedUserId = sessionStorage.getItem("userId");
      if(storedUserId){
        setToken(storedUserId)
      }
      console.log("USER IN PROTECTED ROUTE", storedUserId);

      if (!storedUserId) {
        console.log("Redirecting to /");
        navigate("/");
      }
    };

    checkUser();

    const expirationTime = 60 * 60 * 1000; // 5 minutes in milliseconds
    const timer = setTimeout(() => {
      // Clear the session storage
      sessionStorage.removeItem("userId");
      // Redirect to the login page
      navigate("/"); // Update '/login' with your actual login route
    }, expirationTime);
  }, [navigate]);

  return token ? children : null;
};

export default ProtectedRoute;

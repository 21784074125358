import axios from "axios";

// const URL = "http://localhost:3020";
const URL =process.env.REACT_APP_APIURL;

export const getListOfStatesAndDistricts =async()=>{
  try{
    return  await axios.post(`${URL}/getListOfStatesAndDistricts`)
  }catch(error){
      console.log("Error while calling getListOfStatesAndDistricts ",error)
  }
}


export const getFposNameByDistrict = async(district,st)=>{
    try{
      return  await axios.post(`${URL}/getFposNameByDistrict`,{district,st})
    }catch(error){
        console.log("Error while calling getFposNameByDistrict ",error)
    }
}



export const getFpoByName = async(fpoName,st)=>{
  try{
    return  await axios.post(`${URL}/getFpoByName`,{fpoName,st})
  }catch(error){
      console.log("Error while calling getFpoByName ",error)
  }
}



export const getAllCoordinatesOfFposs = async(st)=>{
  try{
    return  await axios.post(`${URL}/getAllCoordinatesOfFposs`,{st})
  }catch(error){
      console.log("Error while calling getAllCoordinatesOfFposs ",error)
  }
}


export const searchByCrops = async(cropName,st)=>{
  try{
    return  await axios.post(`${URL}/searchByCrops`,{cropName,st})
  }catch(error){
      console.log("Error while calling searchByCrops ",error)
  }
}



export const sendMail = async(data)=>{
  try{
    console.log('cc',data)
    return  await axios.post(`${URL}/v1/user/send-mail`,data)
  }catch(error){
      console.log("Error while calling sendMail ",error)
  }
}

export const getUserDetails= async(st)=>{
  try{
    return  await axios.post(`${URL}/v1/user/get-user`,{ st})
  }catch(error){
      console.log("Error while calling getUserDetails ",error)
  }
}


/////admin access apis

export const addFpoDetails = async(fpoDetails)=>{
  try{
    console.log(fpoDetails,"FPODETAILS")
    return await axios.post(`${URL}/v1/admin/addResource`,fpoDetails)
    
  }catch(error){
    console.log("Error while calling addResource",error);
  }
}

export const editFpoDetails = async(fpoDetails)=>{
  try{
    return await axios.post(`${URL}/v1/admin/editFpoDetails`,fpoDetails)

  }catch(error){
    console.log("Error while calling editFpoDetails",error);
  }
}

export const deleteFpoDetails = async(fpoDetails)=>{
  try{
    return await axios.post(`${URL}/v1/admin/deleteResource`,fpoDetails)

  }catch(error){
    console.log("Error while calling deleteResource",error);
  }
}

export const getAllFpos = async()=>{
  try{
    return await axios.get(`${URL}/v1/admin/getAllResource`)

    
  }catch(error){
    console.log("Error while calling getAllResource",error)
  }
}

export const getFpo = async()=>{
  try{
    return await axios.post(`${URL}/v1/admin/getResource`)

    
  }catch(error){
    console.log("Error while calling getResource",error)
  }
}



// import React from "react";
// import { useRoutes, Navigate } from "react-router-dom";
// // import LoadingComponent from "./components/loader/LoadingComponent"; // Replace with your actual LoadingComponent
// import Mapr from "./components/Mapr";
// import DashboardLayout from "./components/DashboardLayout";
// import Dashboard from "./components/Dashboard/Dashboard";
// import Photos from "./components/Dashboard/Photos";
// import Login from "./components/account/Login";
// // import LoaderSm from './components/loader/LoaderSm';
// import ProtectedRoute from "./ProtectedRoute";

// export default function Router() {
//   const routes = useRoutes([
//     // {
//     //   path: "/",
//     //   element: <Navigate to="/auth" replace />,
//     // },
//     // {
//     //   path: "/auth",
//     //   element: <Login />,
//     //   index: true,
//     // },
//     {
//       path: "/",
//       element: <Login />,
//     },
//     // {
//     //   path: "/auth",
//     //   element: <Login />,
//     //   index: true,
//     // },
//     {
//       path: "/*",
//       // element: (
//       //   <ProtectedRoute>
//       //     <DashboardLayout />
//       //   </ProtectedRoute>
//       // ),
//       element: <DashboardLayout />,

//       children: [
//         { path: "home", element: <Mapr /> },
//         // { path: "maprm", element: <Mapr /> },
//         { path: "dashboard", element: <Dashboard /> },        
//         { path: "dashboard/photos", element: <Photos /> },
//         { path: "home/:state", element: <Mapr /> },
//         { path: "home/:state/:district", element: <Mapr /> },
//         { path: "home/:state/:district/:FPO", element: <Mapr /> },
//       ],
//     },
//     {
//       path: "404",
//       element: <Mapr />,
//     },
//   ]);

//   return routes;
// }



import React, { Suspense } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import LoadingComponent from "./components/loader/LoadingComponent";
import ProtectedRoute from "./ProtectedRoute";


// Lazy load components
const Mapr = React.lazy(() => import("./components/Mapr"));
const DashboardLayout = React.lazy(() => import("./components/DashboardLayout"));
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const Photos = React.lazy(() => import("./components/Dashboard/Photos"));
const Login = React.lazy(() => import("./components/account/Login"));

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/*",
      element: (
        <Suspense fallback={<LoadingComponent/>}>
           <ProtectedRoute>
          <DashboardLayout />
          </ProtectedRoute>
        </Suspense>
      ),
      children: [
        { path: "home", element: <Mapr /> },
        { path: "dashboard", element: <Dashboard /> },        
        { path: "dashboard/photos", element: <Photos /> },
        { path: "home/:state", element: <Mapr /> },
        { path: "home/:state/:district", element: <Mapr /> },
        { path: "home/:state/:district/:FPO", element: <Mapr /> },
      ],
    },
    {
      path: "404",
      element: <Mapr />,
    },
  ]);

  return routes;
}

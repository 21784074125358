import React, { useState } from "react";
import "./App.css";
import { MapProvider } from "./context/MapContext";
import { DataProvider } from "./context/DataContext";
import { AuthProvider } from "./context/AuthContext";
import { DrawerProvider } from "./context/DrawerContext";
import Router from "./routes";

function App() {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showPhotos, setShowPhotos] = useState(false);



  return (
    <div className="view">
      <AuthProvider>
        <MapProvider>
          <DrawerProvider>
            <DataProvider>
              <Router />
            </DataProvider>
          </DrawerProvider>
        </MapProvider>
      </AuthProvider>
    </div>
  );
}

export default App;

//   const Data = [
//     {
//       state: 'Maharashtra',
//       abbreviation: 'MH',
//       District: [
//         {
//           name: 'Nandurbar',
//           FPOs: [
//             {   
//                 fpoName : "FPO 1",
//                 fpo_name: "Astamba Farmer Producer Company",
//                 discription:"Established in 2020, this Farmer Producers Organisation (FPO) has 495 shareholders, predominantly tribal members from neighbouring villages. Currently involved in vermicomposting, the FPO possesses essential tools including a cutter, fibre-making machine, and turner. However, the operational potential is hindered by the absence of an electricity connection. The FPO envisions setting up an integrated plant to process banana stems, utilizing them for the production of paper, bags, and folding files.",
//                 RegistrationNumber:'U01100MP2019PTC048956',
//                 projectCost: "30.87 lakh",
//                 Cost:{
//                   TotalInvestmentRequired: "INR 30.87 lakh",
//                   FPOEquity: 283650,
//                   loans:1876075 ,
//                   GovntSubsidy: 926775,
//                 },
//                 investmentOpportunities:{
//                   electricHauler:13.0,
//                   Solar_panel:19.4,
//                   otherMachinery:67.6,
//                 },
//                 graph: [
//                   {
//                     name: 'Year 1',
//                     Benefits :23.4,
//                     Net_Benefits: -31.3,
//                     Total_Cost: 54.7,
//                   }
                  
//                   ,
//                   {
//                     name: 'Year 2',
//                     Benefits : 29.3,
//                     Net_Benefits: 5.6,
//                     Total_Cost: 23.6,
//                   },
//                   {
//                     name: 'Year 3',
//                     Benefits : 41.0,
//                     Net_Benefits: 11.3,
//                     Total_Cost: 29.6,
//                   },
//                   {
//                     name: 'Year 4',
//                     Benefits : 46.8,
//                     Net_Benefits:13.9,
//                     Total_Cost: 32.9,
//                   },
//                   {
//                     name: 'Year 5',
//                     Benefits :52.7,
//                     Net_Benefits:16.5,
//                     Total_Cost: 36.2,
//                   },
//                   {
//                     name: 'Year 6',
//                     Benefits :58.5,
//                     Net_Benefits: 19.0,
//                     Total_Cost: 39.5,
//                   },
//                   {
//                     name: 'Year 7',
//                     Benefits :58.5,
//                     Net_Benefits:18.4,
//                     Total_Cost: 40.1,
//                   },
//                   {
//                     name: 'Year 8',
//                     Benefits :58.5,
//                     Net_Benefits:17.8,
//                     Total_Cost: 40.7,
//                   },
//                   {
//                     name: 'Year 9',
//                     Benefits :58.5,
//                     Net_Benefits:17.1,
//                     Total_Cost: 41.4,
//                   },
//                   {
//                     name: 'Year 10',
//                     Benefits : 58.5,
//                     Net_Benefits: 16.4,
//                     Total_Cost: 42.1,
//                   },
                  
//                 ],
//                 photos:[
//                   'https://drive.google.com/file/d/1mi7bEFthcRUggzfrnLMS4CnT791afe5z/view?usp=drive_link',
//                   'https://drive.google.com/file/d/1VDMPOtu1mbxsvJOKG14glE7SclVCheII/view?usp=drive_link'             
                    
//                 ],
//                 registrationDate: '24/7/2020',
//                 chairPerson: "Not Appointed",
               
//                 loanStatus: "NA",
//                 focusCrop: "Banana",
                
//                 enviromentalBenefits: "12 tonnes",
//                 pdd: "https://docs.google.com/document/d/18rvlNmtapx0MMGxihAMYueplk4HGV6g7/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//                 latitude: 21.5890231391868,
//                 longitude: 74.1984175756685,
                
//             },

            

//             {
//                 fpoName : "FPO 2",
//                 fpo_name: "Pragati Mahila Bachat Gat",
//                 registrationDate: 2007,
//                 RegistrationNumber:'',
//                 discription:"Maharashtra Arthik Vikas Mahamandal (MAVIM) is a government agency that actively facilitates women's empowerment initiatives through Self Help Groups (SHGs). Collaborating with MAVIM, this FPO has successfully organized 10 producer groups, each comprising 30 women, aimed at enhancing livelihoods. With MAVIM's support, these groups established and operate a millet processing unit, showcasing the collective effort to empower tribal women by fostering economic independence and skill development.",
//                 projectCost: "30.87 lakh",
//                 Cost:{
//                   TotalInvestmentRequired: "INR 32.25 lakh",
//                   FPOEquity: 330000,
//                   loans:2006000 ,
//                   GovntSubsidy: 1089000,
//                 },
//                 investmentOpportunities:{
//                   electricHauler:11.7,
//                   Solar_panel:29.2,
//                   otherMachinery:59.1,
//                 },
//                 graph: [
//                   {
//                     name: 'Year 1',
//                     Benefits :23.4,
//                     Net_Benefits:-31.3,
//                     Total_Cost: 54.7
//                   },
//                   {
//                     name: 'Year 2',
//                     Benefits : 29.3,
//                     Net_Benefits: 5.6,
//                     Total_Cost: 23.6,
//                   },
//                   {
//                     name: 'Year 3',
//                     Benefits : 41.0,
//                     Net_Benefits: 11.3,
//                     Total_Cost: 29.6,
//                   },
//                   {
//                     name: 'Year 4',
//                     Benefits : 46.8,
//                     Net_Benefits:13.9,
//                     Total_Cost: 32.9,
//                   },
//                   {
//                     name: 'Year 5',
//                     Benefits :52.7,
//                     Net_Benefits:16.5,
//                     Total_Cost: 36.2,
//                   },
//                   {
//                     name: 'Year 6',
//                     Benefits :58.5,
//                     Net_Benefits: 19.0,
//                     Total_Cost: 39.5,
//                   },
//                   {
//                     name: 'Year 7',
//                     Benefits :58.5,
//                     Net_Benefits:18.4,
//                     Total_Cost: 40.1,
//                   },
//                   {
//                     name: 'Year 8',
//                     Benefits :58.5,
//                     Net_Benefits:17.8,
//                     Total_Cost: 40.7,
//                   },
//                   {
//                     name: 'Year 9',
//                     Benefits :58.5,
//                     Net_Benefits:17.1,
//                     Total_Cost: 41.4,
//                   },
//                   {
//                     name: 'Year 10',
//                     Benefits : 58.5,
//                     Net_Benefits: 16.4,
//                     Total_Cost: 42.1,
//                   },
                  
//                 ],
//                 photos:[
//                   'https://drive.google.com/file/d/1-kC2sbXi6j6BZz0P3JkN3guvCpYT89RS/view?usp=drive_link',
//                   'https://drive.google.com/file/d/1JDYOMDHh8RrgVWxm7PhQ-VrvDfZTvyxK/view?usp=drive_link'             

//                 ],
//                 chairPerson: "Rekha Padvi",
               
//                 focusCrop: "Millets",
//                 TotalInvestmentRequired: "INR 34.25 lakh",
//                 maxGovntSubsidy: 1089000,
//                 fundingRequire: "INR 23.36 lakhs",
//                 enviromentalBenefits: "12 tonnes",
//                 pdd: "https://docs.google.com/document/d/1rK239FDELEnI780JCwuZ4-CdmA-GK5o5/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//                 latitude: 21.7724442720071,
//                 longitude: 74.0038964700886
//             },



//             {
//               fpoName : "FPO 3",
//               fpo_name: "Ba Aakadata Shetkari Vikas Gat",
//               registrationDate: 2021,
//               RegistrationNumber:'',
//               discription:"Established in 2021, this Farmer Producers Organisation (FPO) has 125 shareholders, including ten women, with a collective cultivation area of approximately 60 acres dedicated to strawberries. Comprising members from nearby villages, the FPO is actively involved in procuring and distributing strawberry plants to farmers, as well as mobilizing them for selling in the local market. The FPO aims to establish a strawberry processing plant, intending to source strawberries directly from its shareholders for the production of items like strawberry crush and other derivatives.              ",
//               projectCost: "26.75 lakh",
//               Cost:{
//                 TotalInvestmentRequired: "INR 26.75 lakh",
//                 FPOEquity: 267450,
//                 loans:1470975 ,
//                 GovntSubsidy: 936075,
//               },
//               investmentOpportunities:{
//                 Solar_panel:37.4,
//                 otherMachinery:62.6,
//               },
//               graph: [
//                 {
//                   name: 'Year 1',
//                   Benefits :64.5,
//                   Net_Benefits:-24.3,
//                   Total_Cost: 88.9
//                 },
//                 {
//                   name: 'Year 2',
//                   Benefits : 80.6,
//                   Net_Benefits:5.1,
//                   Total_Cost: 75.5,
//                 },
//                 {
//                   name: 'Year 3',
//                   Benefits :112.9,
//                   Net_Benefits: 11.0,
//                   Total_Cost:101.9,
//                 },
//                 {
//                   name: 'Year 4',
//                   Benefits : 129.0,
//                   Net_Benefits:13.6,
//                   Total_Cost: 115.4,
//                 },
//                 {
//                   name: 'Year 5',
//                   Benefits :145.2,
//                   Net_Benefits:16.3,
//                   Total_Cost: 128.9,
//                 },
//                 {
//                   name: 'Year 6',
//                   Benefits :161.3,
//                   Net_Benefits: 18.9,
//                   Total_Cost: 142.4,
//                 },
//                 {
//                   name: 'Year 7',
//                   Benefits :161.3,
//                   Net_Benefits:18.2,
//                   Total_Cost: 143.1,
//                 },
//                 {
//                   name: 'Year 8',
//                   Benefits :161.3,
//                   Net_Benefits:17.5,
//                   Total_Cost: 143.8,
//                 },
//                 {
//                   name: 'Year 9',
//                   Benefits :161.3,
//                   Net_Benefits:16.7,
//                   Total_Cost: 144.5,
//                 },
//                 {
//                   name: 'Year 10',
//                   Benefits : 161.3,
//                   Net_Benefits: 16.0,
//                   Total_Cost: 145.3,
//                 },
                
//               ],
//               photos:[
//                 'https://drive.google.com/file/d/1nwztyvg88WzhKLGQiapJ3RGZKpqNlVC7/view?usp=drive_link',
//                 'https://drive.google.com/file/d/1YvKRRYOm46YypusX-jOtX3-OXWqR4cNq/view?usp=drive_link',
//                 'https://drive.google.com/file/d/1CwaJYw1iJU6p6LmVm-qyMD5S7hBm5OlK/view?usp=drive_link',
//                 'https://drive.google.com/file/d/1WU2ErmEjRec5BInfWQ2KvwcyDLaVQcwq/view?usp=drive_link'    

//               ],
//               chairPerson: "Not Appointed",
//               loans: 0,
//               loanStatus: "NA",
//               focusCrop: "Strawberry",
//               TotalInvestmentRequired: "INR 26.75 lakh",
//               maxGovntSubsidy: 936075,
//               fundingRequire: "INR 17.38 lakhs",
//               enviromentalBenefits: "22 tonnes",
//               pdd: "https://docs.google.com/document/d/1R6alU1RVn3WOvqxQJZu3OgydhxsRWp0U/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//               latitude: 21.712351,
//               longitude: 74.02675327 /// check
//               // longitude: 76.89442514 /// check
//             }



            
//           ],
//         },
//         {
//           name: 'Jalgaon',
//           FPOs: [
//             {
//                 fpoName : "FPO 4",
//                 fpo_name: "Rajodak Farmer Producer Company",
//                 registrationDate: "20/06/2020",
//                 RegistrationNumber:'',
//                 discription:"This Farmer Producer Organization (FPO) with 128 members has a turmeric processing facility. The facility sources raw turmeric directly from affiliated farmers, processing it into turmeric powder. The final product is sold in the Sangli market, renowned as Maharashtra's largest turmeric market, establishing a robust market presence for the FPO.  ",
//                 projectCost: "8.8 lakh",
//                 Cost:{
//                   TotalInvestmentRequired: "INR 32.25 lakh",
//                   FPOEquity: 220000,
//                   loans:660000 ,
//                 },
//                 investmentOpportunities:{
//                  Solar_panel:100 
//                 },
//                 graph: [
//                   {
//                     name: 'Year 1',
//                     Benefits :1.2,
//                     Net_Benefits:-2.9,
//                     Total_Cost: 4.0
//                   },
//                   {
//                     name: 'Year 2',
//                     Benefits : 1.4,
//                     Net_Benefits: -0.4,
//                     Total_Cost: 1.8,
//                   },
//                   {
//                     name: 'Year 3',
//                     Benefits : 1.9,
//                     Net_Benefits: 0.1,
//                     Total_Cost:1.8,
//                   },
//                   {
//                     name: 'Year 4',
//                     Benefits : 2.3,
//                     Net_Benefits:0.4,
//                     Total_Cost: 1.8,
//                   },
//                   {
//                     name: 'Year 5',
//                     Benefits :2.4,
//                     Net_Benefits:0.6,
//                     Total_Cost: 1.8,
//                   },
//                   {
//                     name: 'Year 6',
//                     Benefits :2.5,
//                     Net_Benefits: 2.5,
//                     Total_Cost: 0,
//                   },
//                   {
//                     name: 'Year 7',
//                     Benefits :2.6,
//                     Net_Benefits:2.6,
//                     Total_Cost: 0,
//                   },
//                   {
//                     name: 'Year 8',
//                     Benefits :2.6,
//                     Net_Benefits:2.6,
//                     Total_Cost: 0,
//                   },
//                   {
//                     name: 'Year 9',
//                     Benefits :2.7,
//                     Net_Benefits:2.7,
//                     Total_Cost: 0,
//                   },
//                   {
//                     name: 'Year 10',
//                     Benefits : 2.8,
//                     Net_Benefits: 2.8,
//                     Total_Cost: 0,
//                   },
                  
//                 ],
//                 photos:[
//                   'https://drive.google.com/file/d/1rLku5YPNNfwBu-VPSRQ4IP9zjBXquqAi/view?usp=drive_link'    

//                 ],
//                 chairPerson: "Not Appointed",
//                 loans: 0,
//                 loanStatus: "NA",
//                 focusCrop: "Turmeric",
//                 TotalInvestmentRequired: "INR 8.8 lakh",
//                 maxGovntSubsidy: 0,
//                 fundingRequire: "INR 8.8 lakh",
//                 enviromentalBenefits: "21 tonnes",
//                 pdd: "https://docs.google.com/document/d/1IvP5pp0UEzpWG5nPDrwg7vpV2cEIdbB-/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//                 latitude: 21.19560845,
//                 longitude: 75.89442514
//             },

            
           
//           ],
//         },
//       ],
//     },
//     {
//       state: 'MadhyaPradesh',
//       abbreviation: 'MP',
//       District: [
//         {
//           name: 'Chhindwara',
//           FPOs: [
//             {
//               fpoName : "FPO 5",
//             fpo_name: "Amarwara Farmer Producer Company",
//             registrationDate: "24/06/2019",
//             RegistrationNumber:'U01100MP2019PTC048956',
//             discription:"Established in 2019, this Farmer Producers Organisation (FPO) has 450 shareholders from neighbouring villages. The FPO is actively involved in procuring and distributing seeds to local farmers, fostering collective selling initiatives, and has strategic plans to establish an integrated processing plant for millets and pulses—predominant crops in the region. Their unique selling proposition lies in the minimal use of chemicals in locally grown millets, enabling the processed products to bear the coveted organic label. With a focus on local millet procurement and plans for secondary processing, including the production of chips and bakery items, the FPO is currently exploring financing options to realize its vision of establishing the processing plant.",
//               projectCost: "47 lakh",
//               Cost:{
//                 TotalInvestmentRequired: "INR 47 lakh",
//                 FPOEquity: 445600,
//                 loans:3186400 ,
//                 GovntSubsidy: 1074000,
//               },
//               investmentOpportunities:{
//                 electricHauler:8.5,
//                 Solar_panel:21.2,
//                 otherMachinery:70.3,

//               },
//               graph: [
//                 {
//                   name: 'Year 1',
//                   Benefits :43.92,
//                   Net_Benefits:-38,
//                   Total_Cost: 82
//                 },
//                 {
//                   name: 'Year 2',
//                   Benefits : 51.24,
//                   Net_Benefits:11,
//                   Total_Cost: 40,
//                 },
//                 {
//                   name: 'Year 3',
//                   Benefits :58.56,
//                   Net_Benefits: 14,
//                   Total_Cost:44,
//                 },
//                 {
//                   name: 'Year 4',
//                   Benefits : 65.88,
//                   Net_Benefits:17,
//                   Total_Cost: 49,
//                 },
//                 {
//                   name: 'Year 5',
//                   Benefits :73.2,
//                   Net_Benefits:20,
//                   Total_Cost: 53,
//                 },
//                 {
//                   name: 'Year 6',
//                   Benefits :73.2,
//                   Net_Benefits: 19,
//                   Total_Cost: 73.2,
//                 },
//                 {
//                   name: 'Year 7',
//                   Benefits :73.2,
//                   Net_Benefits:18,
//                   Total_Cost: 55,
//                 },
//                 {
//                   name: 'Year 8',
//                   Benefits :73.2,
//                   Net_Benefits:18,
//                   Total_Cost: 56,
//                 },
//                 {
//                   name: 'Year 9',
//                   Benefits :73.2,
//                   Net_Benefits:17,
//                   Total_Cost: 57,
//                 },
//                 {
//                   name: 'Year 10',
//                   Benefits : 161.3,
//                   Net_Benefits: 16,
//                   Total_Cost: 57,
//                 },
                
//               ],
//               photos:[   
//               'https://drive.google.com/file/d/1l9bS3AywH5-pzic6Blu9A3ypS4bMSf8q/view?usp=drive_link',
//               'https://drive.google.com/file/d/1by9lQYmhORyrDeWXMOOhqDqrOyRX33Fw/view?usp=drive_link',

//               ],
//             chairPerson: "Gurudayal Bharati",
//             loans: 0,
//             focusCrop: "Maize, Oil",
//             TotalInvestmentRequired: "INR 47 lakhs",
//             maxGovntSubsidy: 1074000,
//             fundingRequire: "INR 36.32 lakhs",
//             enviromentalBenefits: "23 tonnes",
//             pdd: "https://docs.google.com/document/d/1g7qX6ETlIpdqjXG1Y6Zcxy2PcIweK1Mh/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//             latitude: 22.3070737,
//             longitude: 79.1562763,
//             },



//             {
//                 fpoName : "FPO 6",
//                 fpo_name: "Sausar Farmer Producer Company",
//                 registrationDate: "14/07/2014",
//                 RegistrationNumber:'U01403MP2014PTC032903',
//                 discription:"Established in 2014, this Farmer Producers Organisation (FPO) in the agricultural landscape has gained traction with 650 shareholders from nearby villages and has ambitious plans for further expansion. Currently involved in seed procurement and collective selling initiatives, the FPO is gearing up to establish an integrated processing plant focused on peas, available in abundance in the agriculturally rich district. With the goal of sourcing peas locally, the FPO is actively seeking financing options to enter into secondary processing, producing marketable items like frozen peas.",
//                 // RegistrationNumber:'U01100MP2019PTC048956',
//                 projectCost: "74 lakh",
//                 Cost:{
//                   TotalInvestmentRequired: "INR 74 lakh",
//                   FPOEquity: 715932,
//                   loans:5619388 ,
//                   GovntSubsidy: 1074000,
//                 },
//                 investmentOpportunities:{
//                   electricHauler:5.4,
//                   Solar_panel:23.2,
//                   otherMachinery:71.4,
//                 },
//                 graph: [
//                   {
//                     name: 'Year 1',
//                     Benefits :72,
//                     Net_Benefits: -60,
//                     Total_Cost: 132,
//                   },
//                   {
//                     name: 'Year 2',
//                     Benefits : 84,
//                     Net_Benefits: 17,
//                     Total_Cost: 67,
//                   },
//                   {
//                     name: 'Year 3',
//                     Benefits : 96,
//                     Net_Benefits: 21,
//                     Total_Cost: 75,
//                   },
//                   {
//                     name: 'Year 4',
//                     Benefits : 108,
//                     Net_Benefits:24,
//                     Total_Cost: 84,
//                   },
//                   {
//                     name: 'Year 5',
//                     Benefits :120,
//                     Net_Benefits:27,
//                     Total_Cost: 93,
//                   },
//                   {
//                     name: 'Year 6',
//                     Benefits :120,
//                     Net_Benefits: 27,
//                     Total_Cost: 93,
//                   },
//                   {
//                     name: 'Year 7',
//                     Benefits :120,
//                     Net_Benefits:26,
//                     Total_Cost: 94,
//                   },
//                   {
//                     name: 'Year 8',
//                     Benefits :120,
//                     Net_Benefits:26,
//                     Total_Cost: 94,
//                   },
//                   {
//                     name: 'Year 9',
//                     Benefits :120,
//                     Net_Benefits:25,
//                     Total_Cost: 95,
//                   },
//                   {
//                     name: 'Year 10',
//                     Benefits :120,
//                     Net_Benefits:24,
//                     Total_Cost: 96,
//                   },
                  
//                 ],
//                 photos:[
//                  'https://drive.google.com/file/d/1JRvwsQbzT1Eqe-Ix0ECwvXW8qKR1_eJC/view?usp=drive_link'
//                 ],
            
//                 chairPerson: "Nikhil Raut",
//                 loans: "2 loans:\n850000\n1000000",
//                 loanStatus: "Both repaid",
//                 focusCrop: "Oranges, Frozen Peas",
//                 TotalInvestmentRequired: "INR 74 lakhs",
//                 maxGovntSubsidy: 1074000,
//                 fundingRequire: "INR 63.35 lakhs",
//                 enviromentalBenefits: "43 tonnes",
//                 pdd: "https://docs.google.com/document/d/19NF74RNWvJyqV8CSqL42vwl-XtSLHEUo/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//                 latitude: 21.5946684,
//                 longitude: 78.7380293
//             }
//           ],
//         },


//         {
//           name: 'Singrauli',
//           FPOs: [
//             {
//               fpoName : "FPO 7",
//                 fpo_name: "Deosariya Mahila Farmer Producer Company",
//                 registrationDate: 2/9/2022,
//                 RegistrationNumber:'U01100MP2022PTC059488',
//                 chairPerson: "Horil Singh",
//                 discription:"Established in 2022, this Farmer Producers Organisation (FPO) has 434 shareholders from nearby villages, actively involved in seed procurement and collective selling initiatives. Eyeing the potential in the dominant crops of millets and pulses in the region, the FPO is strategically planning an integrated processing plant. The unique selling proposition lies in the organic cultivation of millets, boasting minimal chemical use, paving the way for processed products to be marketed as organic. With a vision to source millets locally and venture into secondary processing for items like chips and bakery products, the FPO is actively seeking financing options to bring this transformative project to fruition.",
//               projectCost: "47 lakh",
//               Cost:{
//                 TotalInvestmentRequired: "INR 47 lakh",
//                 FPOEquity: 445600,
//                 loans:3186400 ,
//                 GovntSubsidy: 1074000,
//               },
//               investmentOpportunities:{
//                 electricHauler:8.5,
//                 Solar_panel:21.2,
//                 otherMachinery:70.3,

//               },
//               graph: [
//                 {
//                   name: 'Year 1',
//                   Benefits :43.92,
//                   Net_Benefits:-38,
//                   Total_Cost: 82
//                 },
//                 {
//                   name: 'Year 2',
//                   Benefits : 51.24,
//                   Net_Benefits:11,
//                   Total_Cost: 40,
//                 },
//                 {
//                   name: 'Year 3',
//                   Benefits :58.56,
//                   Net_Benefits: 14,
//                   Total_Cost:44,
//                 },
//                 {
//                   name: 'Year 4',
//                   Benefits : 65.88,
//                   Net_Benefits:17,
//                   Total_Cost: 49,
//                 },
//                 {
//                   name: 'Year 5',
//                   Benefits :73.2,
//                   Net_Benefits:20,
//                   Total_Cost: 53,
//                 },
//                 {
//                   name: 'Year 6',
//                   Benefits :73.2,
//                   Net_Benefits: 19,
//                   Total_Cost: 54,
//                 },
//                 {
//                   name: 'Year 7',
//                   Benefits :73.2,
//                   Net_Benefits:18,
//                   Total_Cost: 55,
//                 },
//                 {
//                   name: 'Year 8',
//                   Benefits :73.2,
//                   Net_Benefits:18,
//                   Total_Cost: 56,
//                 },
//                 {
//                   name: 'Year 9',
//                   Benefits :73.2,
//                   Net_Benefits:17,
//                   Total_Cost: 57,
//                 },
//                 {
//                   name: 'Year 10',
//                   Benefits : 73.2,
//                   Net_Benefits: 16,
//                   Total_Cost: 57,
//                 },
                
//               ],
//               photos:[   
//               'https://drive.google.com/file/d/1ypPN2YvgdPr5a3SZor-OuSbPx5nxh0kl/view?usp=drive_link'
//               ],
//                 loans: 0,
//                 loanStatus: "NA",
//                 focusCrop: "Millets and pulses",
//                 TotalInvestmentRequired: "INR 47 lakhs",
//                 maxGovntSubsidy: 1493600,
//                 fundingRequire: "INR 32.12 lakhs",
//                 enviromentalBenefits: "23 tonnes",
//                 pdd: "https://docs.google.com/document/d/1lWZ6WwWxDf8orB5n8E_XZYP6QQBFM8UO/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//                 latitude: 24.3373117,
//                 longitude: 82.1729555
//             },



//             {
//               fpoName : "FPO 8",
//               fpo_name: "Mahan Mahila Farmer Producer Company",
//               registrationDate: "18/08/2015",
//               RegistrationNumber:'U01400MP2015PTC034586',
//               discription:"Established in 2015, this proactive Farmers’ Producers Organisation (FPO) has successfully gathered 778 shareholders from nearby villages. Specializing in seed procurement and collective selling initiatives, the FPO is strategically positioned to expand its impact. With a focus on the dominant crops of millets and pulses in the region, the FPO is planning to establish an integrated processing plant, capitalizing on the organic nature of millets grown locally. Characterized by minimal chemical use, the millets provide a unique selling proposition, enabling the FPO to market its processed products as organic. Intent on sourcing millets locally and venturing into secondary processing for items like chips and bakery products, the FPO is actively seeking financing options to bring this vision to fruition.",
//               projectCost: "47 lakh",
//               Cost:{
//                 TotalInvestmentRequired: "INR 47 lakh",
//                 FPOEquity: 445600,
//                 loans:2766800 ,
//                 GovntSubsidy: 1493600,
//               },
//               investmentOpportunities:{
//                 electricHauler:8.5,
//                 Solar_panel:21.2,
//                 otherMachinery:70.3,

//               },
//               graph: [
//                 {
//                   name: 'Year 1',
//                   Benefits :43.92,
//                   Net_Benefits:-38,
//                   Total_Cost: 82
//                 },
//                 {
//                   name: 'Year 2',
//                   Benefits : 51.24,
//                   Net_Benefits:11,
//                   Total_Cost: 40,
//                 },
//                 {
//                   name: 'Year 3',
//                   Benefits :58.56,
//                   Net_Benefits: 14,
//                   Total_Cost:44,
//                 },
//                 {
//                   name: 'Year 4',
//                   Benefits : 65.88,
//                   Net_Benefits:17,
//                   Total_Cost: 49,
//                 },
//                 {
//                   name: 'Year 5',
//                   Benefits :73.2,
//                   Net_Benefits:20,
//                   Total_Cost: 53,
//                 },
//                 {
//                   name: 'Year 6',
//                   Benefits :73.2,
//                   Net_Benefits: 19,
//                   Total_Cost: 54,
//                 },
//                 {
//                   name: 'Year 7',
//                   Benefits :73.2,
//                   Net_Benefits:18,
//                   Total_Cost: 55,
//                 },
//                 {
//                   name: 'Year 8',
//                   Benefits :73.2,
//                   Net_Benefits:18,
//                   Total_Cost: 56,
//                 },
//                 {
//                   name: 'Year 9',
//                   Benefits :73.2,
//                   Net_Benefits:17,
//                   Total_Cost: 57,
//                 },
//                 {
//                   name: 'Year 10',
//                   Benefits : 73.2,
//                   Net_Benefits: 16,
//                   Total_Cost: 57,
//                 },
                
//               ],
//               photos:[   
//               'https://drive.google.com/file/d/1Xkzpkn2x0dfpQMEwTXt7AUJ0ndVdfN0U/view?usp=drive_link'
//               ],
//               chairPerson: "Prarth Sharma",
//               loans: 0,
//               loanStatus: "NA",
//               focusCrop: "Millets, pulses",
//               TotalInvestmentRequired: "INR 47 lakhs",
//               maxGovntSubsidy: 1493600,
//               fundingRequire: "INR 32.12 lakhs",
//               enviromentalBenefits: "23 tonnes",
//               pdd: "https://docs.google.com/document/d/1_m4iRkPYBXPUayJ2xJuOzNmEyB0cV6os/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//               latitude: 24.1619443,
//               longitude: 82.3181863
//             },



//             {
//                 fpoName : "FPO 9",
//                 fpo_name: "Urjadhani Farmer Producer Company",
//                 registrationDate: "14/03/2022",
//                 RegistrationNumber:'U01100MP2022PTC059937',
//                 discription:"Established in 2022,  this Farmer Producers Organisation (FPO) is engaged in seed procurement and collective selling initiatives, fostering community-driven agricultural practices. The FPO is planning to establish an integrated processing plant for turmeric with a focus on packaging. With a commitment to sourcing tomatoes locally, the FPO envisions further processing to create secondary products such as tomato sauce and powder. Currently exploring financing options, the FPO is poised to contribute significantly to the agricultural processing landscape in the region.",
//                 projectCost: "54 lakh",
//                 Cost:{
//                   TotalInvestmentRequired: "INR 54 lakh",
//                   FPOEquity: 516500,
//                   loans:3824500 ,
//                   GovntSubsidy: 1074000,
//                 },
//                 investmentOpportunities:{
//                   electricHauler:7.4,
//                   Solar_panel:17.7,
//                   solarDryer : 5.2,
//                   otherMachinery:69.7,
  
//                 },
//                 graph: [
//                   {
//                     name: 'Year 1',
//                     Benefits :57,
//                     Net_Benefits:-45,
//                     Total_Cost: 102
//                   },
//                   {
//                     name: 'Year 2',
//                     Benefits : 67,
//                     Net_Benefits:12 ,
//                     Total_Cost: 55,
//                   },
//                   {
//                     name: 'Year 3',
//                     Benefits :76,
//                     Net_Benefits: 15 ,
//                     Total_Cost:61,
//                   },
//                   {
//                     name: 'Year 4',
//                     Benefits : 86,
//                     Net_Benefits:18 ,
//                     Total_Cost:68 ,
//                   },
//                   {
//                     name: 'Year 5',
//                     Benefits :95,
//                     Net_Benefits:20 ,
//                     Total_Cost: 75,
//                   },
//                   {
//                     name: 'Year 6',
//                     Benefits :95,
//                     Net_Benefits: 20 ,
//                     Total_Cost: 76,
//                   },
//                   {
//                     name: 'Year 7',
//                     Benefits :95,
//                     Net_Benefits:19 ,
//                     Total_Cost:76,
//                   },
//                   {
//                     name: 'Year 8',
//                     Benefits :95,
//                     Net_Benefits:18,
//                     Total_Cost: 77 ,
//                   },
//                   {
//                     name: 'Year 9',
//                     Benefits :95,
//                     Net_Benefits:18 ,
//                     Total_Cost: 78 ,
//                   },
//                   {
//                     name: 'Year 10',
//                     Benefits : 95,
//                     Net_Benefits:17 ,
//                     Total_Cost:79,
//                   },
                  
//                 ],
//                 photos:[   
//                 'https://drive.google.com/file/d/1GsZBaAQR9teKdENkdT_DqQfSG5ARfMDF/view?usp=drive_link'
//                 ],
//                 chairPerson: "Avinash Jaisawal",
//                 loans: 0,
//                 loanStatus: "NA",
//                 focusCrop: "Tomato",
//                 TotalInvestmentRequired: "INR 54 lakhs",
//                 maxGovntSubsidy: 1074000,
//                 fundingRequire: "INR 43.41 lakhs",
//                 enviromentalBenefits: "22 tonnes",
//                 pdd: "https://docs.google.com/document/d/1bsUv7G5n5Sn4vQm88CrFxUG3ZxZQrSaj/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
//                 latitude: 23.8907434639998,
//                 longitude: 82.5355499157172
//             }
//           ],
//         },
//       ],
//     },
//   ];
  
// //   //console.log(Data);
// export default Data;

  



































const Data = [
    {
        state: 'Maharashtra',
        abbreviation: 'MH',
        District: [{
                name: 'Nandurbar',
                FPOs: [
                    {
                        fpoName: "FPO 1",
                        fpo_name: "Astamba Farmer Producer Company",
                        discription: "Established in 2020, this Farmer Producers Organisation (FPO) has 495 shareholders, predominantly tribal members from neighbouring villages. Currently involved in vermicomposting, the FPO possesses essential tools including a cutter, fibre-making machine, and turner. However, the operational potential is hindered by the absence of an electricity connection. The FPO envisions setting up an integrated plant to process banana stems, utilizing them for the production of paper, bags, and folding files.",
                        //                 RegistrationNumber:'U01100MP2019PTC04895",
                        RegistrationNumber: 'U01100MP2019PTC048956',
                        projectCost: "30.87 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 30.87 lakh",
                            FPOEquity: 283650,
                            loans: 1876075,
                            GovntSubsidy: 926775,
                        },
                        investmentOpportunities: {
                            electricHauler: 13.0,
                            Solar_panel: 19.4,
                            otherMachinery: 67.6,
                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 14.5,
                                Net_Benefits: -26.3,
                                Total_Cost: 40.8,
                            }

                            ,
                            {
                                name: 'Year 2',
                                Benefits: 18.1,
                                Net_Benefits: 6,
                                Total_Cost: 12.1,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 25.4,
                                Net_Benefits: 8.9,
                                Total_Cost: 16.4,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 29.0,
                                Net_Benefits: 10.4,
                                Total_Cost: 18.6,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 32.6,
                                Net_Benefits: 11.8,
                                Total_Cost: 20.8,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 36.3,
                                Net_Benefits: 13.2,
                                Total_Cost: 23,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 36.3,
                                Net_Benefits: 13.1,
                                Total_Cost: 23.1,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 36.3,
                                Net_Benefits: 13,
                                Total_Cost: 23.2,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 36.3,
                                Net_Benefits: 12.9,
                                Total_Cost: 23.3,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 36.3,
                                Net_Benefits: 12.8,
                                Total_Cost: 23.5,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1mi7bEFthcRUggzfrnLMS4CnT791afe5z/view?usp=drive_link',
                            'https://drive.google.com/file/d/1VDMPOtu1mbxsvJOKG14glE7SclVCheII/view?usp=drive_link'

                        ],
                        registrationDate: 24/7/2020,
                        chairPerson: "Not Appointed",

                        loanStatus: "NA",
                        crop:"Banana",
                        focusCrop: "Banana",

                        enviromentalBenefits: "12 tonnes",
                        pdd: "https://docs.google.com/document/d/18rvlNmtapx0MMGxihAMYueplk4HGV6g7/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 21.5890231391868,
                        longitude: 74.1984175756685,

                    },



                    {
                        fpoName: "FPO 2",
                        fpo_name: "Pragati Mahila Bachat Gat",
                        registrationDate: 2007,
                        // RegistrationNumber:'',
                        discription: "Maharashtra Arthik Vikas Mahamandal (MAVIM) is a government agency that actively facilitates women's empowerment initiatives through Self Help Groups (SHGs). Collaborating with MAVIM, this FPO has successfully organized 10 producer groups, each comprising 30 women, aimed at enhancing livelihoods. With MAVIM's support, these groups established and operate a millet processing unit, showcasing the collective effort to empower tribal women by fostering economic independence and skill development.",
                        RegistrationNumber: 'U01100MP2019PTC048956',
                        projectCost: "34.25 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 34.25 lakh",
                            FPOEquity: 330000,
                            loans: 2006000,
                            GovntSubsidy: 1089000,
                        },
                        investmentOpportunities: {
                            electricHauler: 11.7,
                            Solar_panel: 29.2,
                            otherMachinery: 59.1,
                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 23.4,
                                Net_Benefits: -31.3,
                                Total_Cost: 54.7
                            },
                            {
                                name: 'Year 2',
                                Benefits: 29.3,
                                Net_Benefits: 5.6,
                                Total_Cost: 23.6,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 41.0,
                                Net_Benefits: 11.3,
                                Total_Cost: 29.6,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 46.8,
                                Net_Benefits: 13.9,
                                Total_Cost: 32.9,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 52.7,
                                Net_Benefits: 16.5,
                                Total_Cost: 36.2,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 58.5,
                                Net_Benefits: 19.0,
                                Total_Cost: 39.5,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 58.5,
                                Net_Benefits: 18.4,
                                Total_Cost: 40.1,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 58.5,
                                Net_Benefits: 17.8,
                                Total_Cost: 40.7,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 58.5,
                                Net_Benefits: 17.1,
                                Total_Cost: 41.4,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 58.5,
                                Net_Benefits: 16.4,
                                Total_Cost: 42.1,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1-kC2sbXi6j6BZz0P3JkN3guvCpYT89RS/view?usp=drive_link',
                            'https://drive.google.com/file/d/1JDYOMDHh8RrgVWxm7PhQ-VrvDfZTvyxK/view?usp=drive_link'

                        ],
                        chairPerson: "Rekha Padvi",
                        crop:"Millets",
                        focusCrop: "Millets",
                        TotalInvestmentRequired: "INR 34.25 lakh",
                        maxGovntSubsidy: 1089000,
                        fundingRequire: "INR 23.36 lakhs",
                        enviromentalBenefits: "12 tonnes",
                        pdd: "https://docs.google.com/document/d/1rK239FDELEnI780JCwuZ4-CdmA-GK5o5/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 21.7724442720071,
                        longitude: 74.0038964700886
                    },


                    {
                        fpoName: "FPO 3",
                        fpo_name: "Ba Aakadata Shetkari Vikas Gat",
                        registrationDate: 2021,
                        RegistrationNumber: '',
                        discription: "The FPO is intended to procure strawberries from shareholders and prepare strawberry crush and other products in future.",
                        projectCost: "26.75 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 26.75 lakh",
                            FPOEquity: 267450,
                            loans: 1470975,
                            GovntSubsidy: 936075,
                        },
                        investmentOpportunities: {
                            Solar_panel: 37.4,
                            otherMachinery: 62.6,
                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 64.5,
                                Net_Benefits: -24.3,
                                Total_Cost: 88.9
                            },
                            {
                                name: 'Year 2',
                                Benefits: 80.6,
                                Net_Benefits: 5.1,
                                Total_Cost: 75.5,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 112.9,
                                Net_Benefits: 11.0,
                                Total_Cost: 101.9,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 129.0,
                                Net_Benefits: 13.6,
                                Total_Cost: 115.4,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 145.2,
                                Net_Benefits: 16.3,
                                Total_Cost: 128.9,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 161.3,
                                Net_Benefits: 18.9,
                                Total_Cost: 142.4,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 161.3,
                                Net_Benefits: 18.2,
                                Total_Cost: 143.1,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 161.3,
                                Net_Benefits: 17.5,
                                Total_Cost: 143.8,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 161.3,
                                Net_Benefits: 16.7,
                                Total_Cost: 144.5,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 161.3,
                                Net_Benefits: 16.0,
                                Total_Cost: 145.3,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1nwztyvg88WzhKLGQiapJ3RGZKpqNlVC7/view?usp=drive_link',
                            'https://drive.google.com/file/d/1YvKRRYOm46YypusX-jOtX3-OXWqR4cNq/view?usp=drive_link',
                            'https://drive.google.com/file/d/1CwaJYw1iJU6p6LmVm-qyMD5S7hBm5OlK/view?usp=drive_link',
                            'https://drive.google.com/file/d/1WU2ErmEjRec5BInfWQ2KvwcyDLaVQcwq/view?usp=drive_link'

                        ],
                        chairPerson: "Not Appointed",
                        loans: 0,
                        loanStatus: "NA",
                        crop:"Strawberry",
                        focusCrop: "Strawberry",
                        TotalInvestmentRequired: "INR 26.75 lakh",
                        maxGovntSubsidy: 936075,
                        fundingRequire: "INR 17.38 lakhs",
                        enviromentalBenefits: "22 tonnes",
                        pdd: "https://docs.google.com/document/d/1R6alU1RVn3WOvqxQJZu3OgydhxsRWp0U/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 21.712351,
                        longitude: 74.02675327 /// check
                        // longitude: 76.89442514 /// check
                    }


                ],
            },
            {
                name: 'Jalgaon',
                FPOs: [
                    {
                        fpoName: "FPO 4",
                        fpo_name: "Rajodak Farmer Producer Company",
                        registrationDate: "20/06/2020",
                        RegistrationNumber: '',
                        discription: "This Farmer Producer Organization (FPO) with 128 members has a turmeric processing facility. The facility sources raw turmeric directly from affiliated farmers, processing it into turmeric powder. The final product is sold in the Sangli market, renowned as Maharashtra's largest turmeric market, establishing a robust market presence for the FPO.  ",
                        projectCost: "8.8 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 8.8 lakh",
                            FPOEquity: 220000,
                            loans: 660000,
                        },
                        investmentOpportunities: {
                            Solar_panel: 100
                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 1.2,
                                Net_Benefits: -2.9,
                                Total_Cost: 4.0
                            },
                            {
                                name: 'Year 2',
                                Benefits: 1.4,
                                Net_Benefits: -0.4,
                                Total_Cost: 1.8,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 1.9,
                                Net_Benefits: 0.1,
                                Total_Cost: 1.8,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 2.3,
                                Net_Benefits: 0.4,
                                Total_Cost: 1.8,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 2.4,
                                Net_Benefits: 0.6,
                                Total_Cost: 1.8,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 2.5,
                                Net_Benefits: 2.5,
                                Total_Cost: 0,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 2.6,
                                Net_Benefits: 2.6,
                                Total_Cost: 0,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 2.6,
                                Net_Benefits: 2.6,
                                Total_Cost: 0,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 2.7,
                                Net_Benefits: 2.7,
                                Total_Cost: 0,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 2.8,
                                Net_Benefits: 2.8,
                                Total_Cost: 0,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1rLku5YPNNfwBu-VPSRQ4IP9zjBXquqAi/view?usp=drive_link'

                        ],
                        chairPerson: "Not Appointed",
                        loans: 0,
                        loanStatus: "NA",
                        crop:"Turmeric",
                        focusCrop: "Turmeric",
                        TotalInvestmentRequired: "INR 8.8 lakh",
                        maxGovntSubsidy: 0,
                        fundingRequire: "INR 8.8 lakh",
                        enviromentalBenefits: "21 tonnes",
                        pdd: "https://docs.google.com/document/d/1IvP5pp0UEzpWG5nPDrwg7vpV2cEIdbB-/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 21.19560845,
                        longitude: 75.89442514
                    }


                    
                ],
            },
        ],
    },
    {
        state: 'Madhya Pradesh',
        abbreviation: 'MP',
        District: [{
                name: 'Chhindwara',
                FPOs: [
                    {
                        fpoName: "FPO 5",
                        fpo_name: "Amarwara Farmer Producer Company",
                        registrationDate: "24/06/2019",
                        RegistrationNumber: 'U01100MP2019PTC048956',
                        discription: "Established in 2019, this Farmer Producers Organisation (FPO) has 450 shareholders from neighbouring villages. The FPO is actively involved in procuring and distributing seeds to local farmers, fostering collective selling initiatives, and has strategic plans to establish an integrated processing plant for millets and pulses—predominant crops in the region. Their unique selling proposition lies in the minimal use of chemicals in locally grown millets, enabling the processed products to bear the coveted organic label. With a focus on local millet procurement and plans for secondary processing, including the production of chips and bakery items, the FPO is currently exploring financing options to realize its vision of establishing the processing plant.",
                        projectCost: "47 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 47 lakh",
                            FPOEquity: 445600,
                            loans: 3186400,
                            GovntSubsidy: 1074000,
                        },
                        investmentOpportunities: {
                            electricHauler: 8.5,
                            Solar_panel: 21.2,
                            otherMachinery: 70.3,

                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 43.92,
                                Net_Benefits: -38,
                                Total_Cost: 82
                            },
                            {
                                name: 'Year 2',
                                Benefits: 51.24,
                                Net_Benefits: 11,
                                Total_Cost: 40,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 58.56,
                                Net_Benefits: 14,
                                Total_Cost: 44,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 65.88,
                                Net_Benefits: 17,
                                Total_Cost: 49,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 73.2,
                                Net_Benefits: 20,
                                Total_Cost: 53,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 73.2,
                                Net_Benefits: 19,
                                Total_Cost: 54,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 73.2,
                                Net_Benefits: 18,
                                Total_Cost: 55,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 73.2,
                                Net_Benefits: 18,
                                Total_Cost: 56,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 73.2,
                                Net_Benefits: 17,
                                Total_Cost: 57,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 73.2,
                                Net_Benefits: 16,
                                Total_Cost: 57,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1l9bS3AywH5-pzic6Blu9A3ypS4bMSf8q/view?usp=drive_link',
                            'https://drive.google.com/file/d/1by9lQYmhORyrDeWXMOOhqDqrOyRX33Fw/view?usp=drive_link',

                        ],
                        chairPerson: "Gurudayal Bharati",
                        loans: 0,
                        crop:"Millets",
                        focusCrop: "Maize, Oil",
                        TotalInvestmentRequired: "INR 47 lakhs",
                        maxGovntSubsidy: 1074000,
                        fundingRequire: "INR 36.32 lakhs",
                        enviromentalBenefits: "23 tonnes",
                        pdd: "https://docs.google.com/document/d/1g7qX6ETlIpdqjXG1Y6Zcxy2PcIweK1Mh/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 22.3070737,
                        longitude: 79.1562763,
                    },



                    {
                        fpoName: "FPO 6",
                        fpo_name: "Sausar Farmer Producer Company",
                        registrationDate: "14/07/2014",
                        RegistrationNumber: 'U01403MP2014PTC032903',
                        discription: "Established in 2014, this Farmer Producers Organisation (FPO) in the agricultural landscape has gained traction with 650 shareholders from nearby villages and has ambitious plans for further expansion. Currently involved in seed procurement and collective selling initiatives, the FPO is gearing up to establish an integrated processing plant focused on peas, available in abundance in the agriculturally rich district. With the goal of sourcing peas locally, the FPO is actively seeking financing options to enter into secondary processing, producing marketable items like frozen peas.",
                        // RegistrationNumber:'U01100MP2019PTC048956',
                        projectCost: "74 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 74 lakh",
                            FPOEquity: 715932,
                            loans: 5619388,
                            GovntSubsidy: 1074000,
                        },
                        investmentOpportunities: {
                            electricHauler: 5.4,
                            Solar_panel: 23.2,
                            otherMachinery: 71.4,
                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 72,
                                Net_Benefits: -60,
                                Total_Cost: 132,
                            },
                            {
                                name: 'Year 2',
                                Benefits: 84,
                                Net_Benefits: 17,
                                Total_Cost: 67,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 96,
                                Net_Benefits: 21,
                                Total_Cost: 75,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 108,
                                Net_Benefits: 24,
                                Total_Cost: 84,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 120,
                                Net_Benefits: 27,
                                Total_Cost: 93,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 120,
                                Net_Benefits: 27,
                                Total_Cost: 93,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 120,
                                Net_Benefits: 26,
                                Total_Cost: 94,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 120,
                                Net_Benefits: 26,
                                Total_Cost: 94,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 120,
                                Net_Benefits: 25,
                                Total_Cost: 95,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 120,
                                Net_Benefits: 24,
                                Total_Cost: 96,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1JRvwsQbzT1Eqe-Ix0ECwvXW8qKR1_eJC/view?usp=drive_link'
                        ],

                        chairPerson: "Nikhil Raut",
                        loans: "2 loans:\n850000\n1000000",
                        loanStatus: "Both repaid",
                        crop:"Frozen Peas",
                        focusCrop: "Oranges, Frozen Peas",
                        TotalInvestmentRequired: "INR 74 lakhs",
                        maxGovntSubsidy: 1074000,
                        fundingRequire: "INR 63.35 lakhs",
                        enviromentalBenefits: "42 tonnes",
                        pdd: "https://docs.google.com/document/d/19NF74RNWvJyqV8CSqL42vwl-XtSLHEUo/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 21.5946684,
                        longitude: 78.7380293
                    }
                ],
            },


            {
                name: 'Singrauli',
                FPOs: [
                    {
                        fpoName: "FPO 7",
                        fpo_name: "Deosariya Mahila Farmer Producer Company",
                        registrationDate: 2 / 9 / 2022,
                        RegistrationNumber: 'U01100MP2022PTC059488',
                        chairPerson: "Horil Singh",
                        discription: "Established in 2022, this Farmer Producers Organisation (FPO) has 434 shareholders from nearby villages, actively involved in seed procurement and collective selling initiatives. Eyeing the potential in the dominant crops of millets and pulses in the region, the FPO is strategically planning an integrated processing plant. The unique selling proposition lies in the organic cultivation of millets, boasting minimal chemical use, paving the way for processed products to be marketed as organic. With a vision to source millets locally and venture into secondary processing for items like chips and bakery products, the FPO is actively seeking financing options to bring this transformative project to fruition",
                        projectCost: "47 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 47 lakh",
                            FPOEquity: 445600,
                            loans: 3186400,
                            GovntSubsidy: 1074000,
                        },
                        investmentOpportunities: {
                            electricHauler: 8.5,
                            Solar_panel: 21.2,
                            otherMachinery: 70.3,

                        },
                        graph: [
                            {
                                name: 'Year 1',
                                Benefits: 43.92,
                                Net_Benefits: -38,
                                Total_Cost: 82
                            },
                            {
                                name: 'Year 2',
                                Benefits: 51.24,
                                Net_Benefits: 11,
                                Total_Cost: 40,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 58.56,
                                Net_Benefits: 14,
                                Total_Cost: 44,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 65.88,
                                Net_Benefits: 17,
                                Total_Cost: 49,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 73.2,
                                Net_Benefits: 20,
                                Total_Cost: 53,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 73.2,
                                Net_Benefits: 19,
                                Total_Cost: 54,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 73.2,
                                Net_Benefits: 18,
                                Total_Cost: 55,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 73.2,
                                Net_Benefits: 18,
                                Total_Cost: 56,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 73.2,
                                Net_Benefits: 17,
                                Total_Cost: 57,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 73.2,
                                Net_Benefits: 16,
                                Total_Cost: 57,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1ypPN2YvgdPr5a3SZor-OuSbPx5nxh0kl/view?usp=drive_link'
                        ],
                        loans: 0,
                        loanStatus: "NA",
                        crop:"Millets",
                        focusCrop: "Millets and pulses",
                        TotalInvestmentRequired: "INR 47 lakhs",
                        maxGovntSubsidy: 1074000,
                        fundingRequire: "INR 36.32 lakhs",
                        enviromentalBenefits: "22 tonnes",
                        pdd: "https://docs.google.com/document/d/1lWZ6WwWxDf8orB5n8E_XZYP6QQBFM8UO/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 24.3373117,
                        longitude: 82.1729555
                    },



                    {
                        fpoName: "FPO 8",
                        fpo_name: "Mahan Mahila Farmer Producer Company",
                        registrationDate: "18/08/2015",
                        RegistrationNumber: 'U01400MP2015PTC034586',
                        discription: "Established in 2015, this proactive Farmers’ Producers Organisation (FPO) has successfully gathered 778 shareholders from nearby villages. Specializing in seed procurement and collective selling initiatives, the FPO is strategically positioned to expand its impact. With a focus on the dominant crops of millets and pulses in the region, the FPO is planning to establish an integrated processing plant, capitalizing on the organic nature of millets grown locally. Characterized by minimal chemical use, the millets provide a unique selling proposition, enabling the FPO to market its processed products as organic. Intent on sourcing millets locally and venturing into secondary processing for items like chips and bakery products, the FPO is actively seeking financing options to bring this vision to fruition.",
                        projectCost: "47 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 47 lakh",
                            FPOEquity: 445600,
                            loans: 3186400,
                            GovntSubsidy: 1074000,
                        },
                        investmentOpportunities: {
                            electricHauler: 8.5,
                            Solar_panel: 21.2,
                            otherMachinery: 70.3,

                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 43.92,
                                Net_Benefits: -38,
                                Total_Cost: 82
                            },
                            {
                                name: 'Year 2',
                                Benefits: 51.24,
                                Net_Benefits: 11,
                                Total_Cost: 40,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 58.56,
                                Net_Benefits: 14,
                                Total_Cost: 44,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 65.88,
                                Net_Benefits: 17,
                                Total_Cost: 49,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 73.2,
                                Net_Benefits: 20,
                                Total_Cost: 53,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 73.2,
                                Net_Benefits: 19,
                                Total_Cost: 54,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 73.2,
                                Net_Benefits: 18,
                                Total_Cost: 55,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 73.2,
                                Net_Benefits: 18,
                                Total_Cost: 56,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 73.2,
                                Net_Benefits: 17,
                                Total_Cost: 57,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 73.2,
                                Net_Benefits: 16,
                                Total_Cost: 57,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1Xkzpkn2x0dfpQMEwTXt7AUJ0ndVdfN0U/view?usp=drive_link'
                        ],
                        chairPerson: "Prarth Sharma",
                        loans: 0,
                        loanStatus: "NA",
                        crop:"Millets",
                        focusCrop: "Millets, pulses",
                        TotalInvestmentRequired: "INR 47 lakhs",
                        maxGovntSubsidy: 1074000,
                        fundingRequire: "INR 36.32 lakhs",
                        enviromentalBenefits: "23 tonnes",
                        pdd: "https://docs.google.com/document/d/1_m4iRkPYBXPUayJ2xJuOzNmEyB0cV6os/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 24.1619443,
                        longitude: 82.3181863
                    },



                    {
                        fpoName: "FPO 9",
                        fpo_name: "Urjadhani Farmer Producer Company",
                        registrationDate: "14/03/2022",
                        RegistrationNumber: 'U01100MP2022PTC059937',
                        discription: "Established in 2022,  this Farmer Producers Organisation (FPO) is engaged in seed procurement and collective selling initiatives, fostering community-driven agricultural practices. The FPO is planning to establish an integrated processing plant for turmeric with a focus on packaging. With a commitment to sourcing tomatoes locally, the FPO envisions further processing to create secondary products such as tomato sauce and powder. Currently exploring financing options, the FPO is poised to contribute significantly to the agricultural processing landscape in the region.",
                        projectCost: "54 lakh",
                        Cost: {
                            TotalInvestmentRequired: "INR 54 lakh",
                            FPOEquity: 516500,
                            loans: 3824500,
                            GovntSubsidy: 1074000,
                        },
                        investmentOpportunities: {
                            electricHauler: 7.4,
                            Solar_panel: 17.7,
                            solarDryer: 5.2,
                            otherMachinery: 69.7,

                        },
                        graph: [{
                                name: 'Year 1',
                                Benefits: 57,
                                Net_Benefits: -45,
                                Total_Cost: 102
                            },
                            {
                                name: 'Year 2',
                                Benefits: 67,
                                Net_Benefits: 12,
                                Total_Cost: 55,
                            },
                            {
                                name: 'Year 3',
                                Benefits: 76,
                                Net_Benefits: 15,
                                Total_Cost: 61,
                            },
                            {
                                name: 'Year 4',
                                Benefits: 86,
                                Net_Benefits: 18,
                                Total_Cost: 68,
                            },
                            {
                                name: 'Year 5',
                                Benefits: 95,
                                Net_Benefits: 20,
                                Total_Cost: 75,
                            },
                            {
                                name: 'Year 6',
                                Benefits: 95,
                                Net_Benefits: 20,
                                Total_Cost: 76,
                            },
                            {
                                name: 'Year 7',
                                Benefits: 95,
                                Net_Benefits: 19,
                                Total_Cost: 76,
                            },
                            {
                                name: 'Year 8',
                                Benefits: 95,
                                Net_Benefits: 18,
                                Total_Cost: 77,
                            },
                            {
                                name: 'Year 9',
                                Benefits: 95,
                                Net_Benefits: 18,
                                Total_Cost: 78,
                            },
                            {
                                name: 'Year 10',
                                Benefits: 95,
                                Net_Benefits: 17,
                                Total_Cost: 79,
                            },

                        ],
                        photos: [
                            'https://drive.google.com/file/d/1GsZBaAQR9teKdENkdT_DqQfSG5ARfMDF/view?usp=drive_link'
                        ],
                        chairPerson: "Avinash Jaisawal",
                        loans: 0,
                        loanStatus: "NA",
                        crop:"Tomato",
                        focusCrop: "Tomato",
                        TotalInvestmentRequired: "INR 54 lakhs",
                        maxGovntSubsidy: 1074000,
                        fundingRequire: "INR 43.41 lakhs",
                        enviromentalBenefits: "21 tonnes",
                        pdd: "https://docs.google.com/document/d/1bsUv7G5n5Sn4vQm88CrFxUG3ZxZQrSaj/edit?usp=drive_link&ouid=101049218561124262331&rtpof=true&sd=true",
                        latitude: 23.8907434639998,
                        longitude: 82.5355499157172
                    }
                ],
            },
        ],
    },
];

//console.log(Data);
export default Data;
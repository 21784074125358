const location = [
    {
     state:"Maharashtra",
     Latitude:19.7515,
     Longitude:75.7139,
     district:[
        {
            Name:"Nandurbar",
            Latitude:21.3693,
            Longitude:74.2405, 
        },
        {
            Name:"Jalgaon",
            Latitude:21.0077,
            Longitude:75.5626, 
        }
    ]
    },
    {
     state:"Madhya Pradesh",
     Latitude:22.9734,
     Longitude:78.6569,
     district:[
        {
            Name:"Chhindwara",
            Latitude:22.0574,
            Longitude:78.9382, 
        },
        {
            Name:"Singrauli",
            Latitude:24.2432,
            Longitude:82.4753, 
        }
    ]
     
    }
]

export default  location;
import { createContext, useState } from "react";

export const AppContext = createContext();

export default function AppContextProvider({ children }) {
  const [active, setActive] = useState(true);

  const value = {
    active,
    setActive,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

import React, { createContext, useContext, useEffect, useState } from "react";
import datas from "../assets/data/Data";
import locations from "../assets/data/Coordinates";

import dataa from "../assets/data/Data";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [location, setLocation] = useState([]);
  const [data, setData] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [fpos, setFpos] = useState([]);
  const [lengthFPO, setLength] = useState(0);

  const [fpo, setFpo] = useState([]);
  const [path, setPath] = useState({
    state: "",
    district: "",
    fpoName: "",
  });
  const [goBack, setGoBack] = useState({
    state: { lat: "", lng: "" },
    district: { lat: "", lng: "" },
  });
  const [coordinates, setCoordinates] = useState();
  const [searchedResults, setSearchResults] = useState([]);
  const [searchedFpo, setSearchedFpo] = useState([]);

  const removeDataFromLocalStorage = () => {
    localStorage.removeItem("zoomLevel");
    localStorage.removeItem("AppState");
    localStorage.removeItem("isOpen");
    localStorage.removeItem("mapCenter");
    localStorage.removeItem("path");
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Check if it's a page reload or page navigation
      if (
        event.persisted ||
        (performance && performance.navigation.type === 1)
      ) {
        // Page is being reloaded, do nothing
        return;
      }

      // Page is being closed, call the function to remove data
      removeDataFromLocalStorage();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchLocation = () => {
    setLocation(locations);
    //console.log("Location of States", locations);
  };

  const fetchData = () => {
    setData(datas);
    //console.log("Location of Statess", datas);
  };

  const District = (stateName) => {
    //console.log("calledDistrict")
    //console.log(stateName,"calledDistrict")
    //console.log("All states in location:", location);

    const stateData = location.find((state) => state.state === stateName);
    //console.log(stateData,"stateData")

    if (stateData) {
      const districtNames = stateData.district.map((district) => district);
      setDistricts(districtNames);

      //console.log("district names",districtNames)
    } else {
      setDistricts([]);
    }
    // setPath({ ...path, state: stateName });

    getAllCoordinatesOfFposs(stateName);
  };

  function getFPOsNameByDistrict(targetDistrict) {
    //console.log("targetDistrict", targetDistrict);

    const fpoData = datas.flatMap((state) =>
      (state.District || [])
        .filter((district) => district.name === targetDistrict)
        .flatMap((district) =>
          (district.FPOs || []).map((fpo) => ({
            fpoName: fpo.fpo_name,
            crop: fpo.crop,
            latitude: fpo.latitude,
            longitude: fpo.longitude,
            FPOName: fpo.fpoName,
          }))
        )
    );

    //console.log('FPOSSSSSSSSSSSS', fpoData);

    // Update the state with the fetched FPO data
    setFpos(fpoData);
    // console.log("length in data:",length);
    // setPath({ ...path, district: targetDistrict });
    filterDistrictCoordinates(targetDistrict);
  }

  function getFPOByName(fpoName, searched) {
    const result = datas
      .flatMap((state) =>
        state.District.flatMap((district) =>
          district.FPOs.filter((fpo) => fpo.fpoName === fpoName).map(
            (foundFpo) => ({
              fpo: foundFpo,
              district: district.name, // Assuming your district object has a 'name' property
            })
          )
        )
      )
      .find(Boolean);
    if (searched) {
      setSearchedFpo(result.fpo);
    } else {
      setFpo(result);
      // console.log(result,"changedFPO")
      return result;
    }
    // setPath({...path,fpoName:fpoName})
  }

  function getAllCoordinatesOfFpos() {
    const coordinatesArray = datas
      .flatMap((state) =>
        (state.District || []).flatMap((district) =>
          (district.FPOs || []).flatMap((fpo) => {
            const { latitude, longitude } = fpo;
            if (latitude !== undefined && longitude !== undefined) {
              return { latitude, longitude };
            } else {
              return null;
            }
          })
        )
      )
      .filter((coordinate) => coordinate !== null);

    setCoordinates(coordinatesArray);
    //console.log("coordinatesArray", coordinatesArray);
  }

  const getLength = () => {
    const result = datas.map((state) => state.District);
    return result;
  };

  // Log the result
  // console.log(getLength(),"fpoCountByState");

  const fetchDataOnReload = () => {
    if (localStorage.getItem("path")) {
      const jsonData = localStorage.getItem("path");
      //console.log(jsonData, "JSON");

      const parsedData = JSON.parse(jsonData);
      //console.log(parsedData, "PARSED DATA");
      setPath(parsedData);

      if (parsedData.state) {
        //console.log(parsedData.state, "PARSED STATE");
        District(parsedData.state);
      }

      if (parsedData.district) {
        //console.log(parsedData.district, "PARSED DISTRICT");
        getFPOsNameByDistrict(parsedData.district);
      }
    }
  };

  // useEffect(() => {
  //   fetchDataOnReload();
  // }, []);

  // function getAllCoordinatesOfFpos(districtName) {
  //   const coordinatesArray = datas
  //     .flatMap((state) =>
  //       (state.District || [])
  //         .filter((district) => district.name === districtName)
  //         .flatMap((district) =>
  //           (district.FPOs || []).map((fpo) => {
  //             const { latitude, longitude } = fpo;
  //             return { latitude, longitude };
  //           })
  //         )
  //     )
  //     .filter((coordinate) => coordinate !== null);

  //   setCoordinates(coordinatesArray);
  //   //console.log("coordinatesArray", coordinatesArray);
  // }

  // function getAllCoordinatesOfFpos(stateName) {
  //   const coordinatesArray = datas
  //     .filter((state) => state.state === stateName)
  //     .flatMap((state) =>
  //       (state.District || []).flatMap((district) =>
  //         (district.FPOs || []).map((fpo) => {
  //           const { latitude, longitude } = fpo;
  //           return { latitude, longitude };
  //         })
  //       )
  //     )
  //     .filter((coordinate) => coordinate !== null);

  //   setCoordinates(coordinatesArray);
  //   //console.log("coordinatesArray", coordinatesArray);
  // }

  function getAllCoordinatesOfFposs(stateName) {
    const coordinatesArray = datas
      .filter((state) => state.state === stateName)
      .map((state) =>
        (state.District || [])
          .map((district) =>
            (district.FPOs || [])
              .filter(
                (fpo) =>
                  fpo.latitude !== undefined && fpo.longitude !== undefined
              )
              .map((fpo) => ({
                latitude: fpo.latitude,
                fpoName: fpo.fpoName,
                crop: fpo.crop,
                longitude: fpo.longitude,
              }))
          )
          .flat()
      )
      .flat()
      .filter((coordinate) => coordinate !== null);

    setCoordinates(coordinatesArray);

    //console.log("coordinatesArrays", coordinatesArray);
  }

  // function getFPOCountByDistrictForEveryState() {
  //   datas.forEach((state) => {
  //     const stateName = state.state;

  //     (state.District || []).forEach((district) => {
  //       const districtName = district.name;
  //       const fpoCount = (district.FPOs || []).length;

  //       console.log(`FPO count in ${districtName}, ${stateName}: ${fpoCount}`);
  //     });
  //   });
  // }

  // // Call the function to get the count of FPOs in each district for all states
  // getFPOCountByDistrictForEveryState();

  // Call the function to get the length of FPOs in each state

  // Example usage:
  // getAllCoordinatesOfFpos("YourStateName");

  // Example usage:

  // Example usage:

  const storePathInLocalStorage = async (state, district, fpoName) => {
    // Retrieve existing data from localStorage
    const existingData = JSON.parse(localStorage.getItem("path")) || {};

    // Update the specific properties
    const updatedPath = {
      ...existingData,
      state: state !== "" ? state : existingData.state,
      district: district !== "" ? district : existingData.district,
      fpoName: fpoName !== "" ? fpoName : existingData.fpoName,
    };

    // Update the state
    await setPath(updatedPath);

    // Store the updated path in localStorage
    localStorage.setItem("path", JSON.stringify(updatedPath));
  };

  const filterDistrictCoordinates = (districtName) => {
    const filteredDistrict = districts.filter((district) => {
      // Assuming 'Name' is the property representing the district name
      return district.Name === districtName;
    });
    //console.log(filteredDistrict,"INSIDE ")

    setGoBack({
      ...goBack,
      district: {
        lat: filteredDistrict.latitude,
        lng: filteredDistrict.longitude,
      },
    });

    //console.log(filteredDistrict, "Filtered Coordinates for", districtName);
  };

  // Example usage:

  const storeCoordinatesInLs = (goBack) => {
    // Assuming goBack is an object with state and districts properties

    //console.log("GOBACK",goBack)
    const jsonData = JSON.stringify(goBack);
    localStorage.setItem("goBackCoordinates", jsonData);
  };

  useEffect(() => {
    const lengthOfFpo = () => {
      // var allDistricts = dataa.map(item => item.District.map(district => district));
      //  console.log("dataa in contex" , dataa)
      // all districts here
      var allDistricts = dataa.map((item) => item.District).flat();

      // console.log("allDistricts : ",allDistricts)
      // setLength(allDistricts);
      setLength(dataa);

      // const namedist  = allDistricts.map(id=>id.name);

      // console.log("allDistricts name: ",namedist)

      // Assuming allDistricts is your array
      // var matchingDistricts = allDistricts.filter(district => district.name === 'Nandurbar');

      // var matchingDistrictNames = matchingDistricts.map(district => district.name);

      // Print the result
      // console.log("Matching District Names:", matchingDistrictNames);
    };
    lengthOfFpo();
  }, []);
  // const lengthOfFpo = ()=>{
  //   // var allDistricts = dataa.map(item => item.District.map(district => district));

  //   // all districts here
  //   var allDistricts = dataa.map(item => item.District).flat();

  //   console.log("allDistricts : ",allDistricts)
  //   setLength(allDistricts);

  //   // const namedist  = allDistricts.map(id=>id.name);

  //   // console.log("allDistricts name: ",namedist)

  //    // Assuming allDistricts is your array
  //       // var matchingDistricts = allDistricts.filter(district => district.name === 'Nandurbar');

  //       // var matchingDistrictNames = matchingDistricts.map(district => district.name);

  //       // Print the result
  //       // console.log("Matching District Names:", matchingDistrictNames);

  // }

  const searchByCrops = (cropName) => {
    if (cropName) {
      const lowercaseCropName = cropName.toLowerCase();

      // Filter FPOs based on the target crop in a specific district
      const fpoNames = datas
        .map((state) => state.District)
        .flatMap((district) =>
          district
            .map((fpos) => fpos.FPOs)
            .flatMap((fpos) =>
              fpos
                .filter((fpo) =>
                  fpo.focusCrop.toLowerCase().includes(lowercaseCropName)
                )
                .map((fpo) => ({
                  fpoName: fpo.fpoName,
                  latitude: fpo.latitude,
                  longitude: fpo.longitude,
                  crop:      fpo.crop
                }))
            )
        );

      setSearchResults(fpoNames);

      // Set an empty array if there are no results
      if (fpoNames.length === 0) {
        setSearchResults([]);
      }

      //console.log("FPO Names for Crop", cropName, ":", fpoNames);
    }
  };

  const getDataForUncluster = (fpoName) => {
    const fpo = getFPOByName(fpoName);
    //  console.log("FPOS for uncluster",fpo)
    setFpo(fpo);
    storePathInLocalStorage("", fpo.district, fpo.fpo.fpoName);
  };

  useEffect(() => {
    const fetchDataOnMount = async () => {
      // Fetch location data
      await fetchLocation();

      // Fetch data
      await fetchData();

      // Call fetchDataOnReload after fetching data
      fetchDataOnReload();
    };

    fetchDataOnMount();
  }, [location]);

  //console.log("locations",locations)
  //console.log("data=====>",data)
  return (
    <DataContext.Provider
      value={{
        getDataForUncluster,
        searchByCrops,
        searchedFpo,
        setSearchedFpo,
        searchedResults,
        location,
        data,
        District,
        districts,
        getFPOsNameByDistrict,
        fpos,
        getFPOByName,
        fpo,
        setPath,
        path,
        coordinates,
        storePathInLocalStorage,
        lengthFPO,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};

import React, { useState, useEffect } from "react";
import "./LoadingComponent.css";

const LoadingComponent = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    const fetchData = async () => {
      try {
        // Simulate a delay (e.g., API request)
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          {/* Your content when loading is complete */}
          {/* <p>Data loaded successfully!</p> */}
          {/* Add your actual content here */}
        </div>
      )}
    </div>
  );
};

export default LoadingComponent;

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
// import indiaStates from "../assets/Data/indian_states.json"
import indiaDistricts from "../assets/data/india_district.json";
import Coordinates from "../assets/data/Coordinates";

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [mapCenter, setMapCenter] = useState({
    lat: 22.7515,
    lng: 75.7139,
  });

  const [toggleMap, setToggleMap] = useState(false);
  const mapRef = useRef(null);

  const [oldMapCenter, setOldMapCenter] = useState({ lat: "", lng: "" });
  const [oldMapZoom, setOldMapZoom] = useState();

  const [filteredState, setFilteredState] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(3.6);

  const [goBack, setGoBack] = useState({
    state: { lat: "", lng: "", zoom: "" },
    district: { lat: "", lng: "", zoom: "" },
  });

  const previousLocation = (locationType, latitude, longitude, zoom) => {
    setGoBack((prevState) => ({
      ...prevState,
      [locationType]: { lat: latitude, lng: longitude, zoom: zoom },
    }));
  };

  const goToIndiaMap = () => {
    // Check if the item exists in localStorage
    if (localStorage.getItem("mapCenter")) {
      // Remove the item from localStorage
      const center = { lat: 22.7515, lng: 75.7139 }; // Set your desired initial center
      const initialData = JSON.stringify(center);
      localStorage.setItem("mapCenter", initialData);
      updateMapCenter(center.lat, center.lng);
      updateZoomLevel(3.6);
    } else {
      const center = { lat: 22.7515, lng: 75.7139 }; // Set your desired initial center
      const initialData = JSON.stringify(center);
      localStorage.setItem("mapCenter", initialData);
      updateMapCenter(center.lat, center.lng);
      updateZoomLevel(3.6);
    }
  };

  // const getStateCoordinates = (stateName) => {};

  // const goBackToState = () => {};

  // Filtering districts for Maharashtra
  const filterState = (stateName) => {
    //console.log("StateNAME",stateName)
    const state = indiaDistricts.features.filter(
      (district) => district.properties.NAME_1 === stateName
    );
    setFilteredState(state);
  };

  const updateMapCenter = (lat, lng) => {
    // Update the state first
    const newCenter = { lat, lng };
    setMapCenter((prevState) => {
      const jsonData = JSON.stringify(prevState);
      localStorage.setItem("oldMapCenter", jsonData);
      setOldMapCenter(prevState);
      //console.log(prevState); // Log the previous state
      return newCenter; // Set the new state value
    });
    //console.log("NEWCENTER",newCenter)
    // Then update localStorage
    const jsonData = JSON.stringify(newCenter);
    localStorage.setItem("mapCenter", jsonData);
  };

  const updateMapCenterRefreshed = () => {
    const jsonData = localStorage.getItem("mapCenter");
    if (jsonData) {
      const parsedCenter = JSON.parse(jsonData);
      if (
        parsedCenter.lat !== mapCenter.lat ||
        parsedCenter.lng !== mapCenter.lng
      ) {
        updateMapCenter(parsedCenter.lat, parsedCenter.lng);
      }
    } else {
      // If data is not in localStorage, add an initial value
      const initialCenter = { lat: 22.7515, lng: 75.7139 }; // Set your desired initial center
      const initialData = JSON.stringify(initialCenter);
      localStorage.setItem("mapCenter", initialData);
    }
  };

  //   const updateMapCenterRefreshed = ()=>{
  //     const jsonData = localStorage.getItem("mapCenter");

  //     if(jsonData){
  //         const parsedCenter = JSON.parse(jsonData);
  //         setMapCenter({lat:parsedCenter.lat,lng:parsedCenter.lng})
  //     }
  //   }

  const updateZoomLevel = (zoomLevel) => {
    // console.log("zoomLevel",zoomLevel)
    setZoomLevel((prevZoom) => {
      setOldMapZoom(prevZoom);
      const jsonData = JSON.stringify(prevZoom);
      localStorage.setItem("oldMapZoom", jsonData);
      const newZoom = zoomLevel;
      const jsonZoom = JSON.stringify(newZoom);
      localStorage.setItem("zoomLevel", jsonZoom);
      return newZoom;
    });
  };

  // const updateOldZoomLevel = (zoomLevel)=>{
  //   setZoomLevel((prevZoom)=>{
  //     const newZoom = zoomLevel;
  //       const jsonZoom = JSON.stringify(newZoom);
  //       localStorage.setItem("zoomLevel", jsonZoom);
  //       return newZoom;
  //   })
  // }

  // const updateZoomLevel = (zoomLevel)=>{
  //     setZoomLevel(zoomLevel)
  // }

  const updateZoomLevelRefreshed = () => {
    const jsonData = localStorage.getItem("zoomLevel");
    if (jsonData) {
      const parsedData = JSON.parse(jsonData);
      setZoomLevel(parsedData);
    } else {
      // If data is not in localStorage, add an initial value
      const initialZoom = 3.5; // Replace this with your desired initial value
      const initialData = JSON.stringify(initialZoom);
      localStorage.setItem("zoomLevel", initialData);
      setZoomLevel(initialZoom);
    }
  };

  // useEffect(() => {
  //   updateMapCenterRefreshed();
  // }, [mapCenter]);

  const getStateAndDistrictCoordinates = () => {
    const jsonData = localStorage.getItem("path");
    if (jsonData) {
      const parsedPath = JSON.parse(jsonData);
      //console.log(parsedPath,"CHECKING");

      if (parsedPath.state && parsedPath.state !== "none") {
        const stateCoordinates = Coordinates.find(
          (coordinate) => coordinate.state === parsedPath.state
        );
        //console.log(stateCoordinates,"STATECOORDINATES")
        updateMapCenter(stateCoordinates.Latitude, stateCoordinates.Longitude);
        updateZoomLevel(5.8);
        setGoBack((prevState) => ({
          ...prevState,
          state: {
            lat: stateCoordinates.Latitude,
            lng: stateCoordinates.Longitude,
            zoom: 5.8,
          },
        }));

        //console.log(goBack.state.lat, goBack.state.lng ,"code not coming here");

        if (parsedPath.district && parsedPath.district !== "none") {
          const districtCoordinates = Coordinates.flatMap(
            (loc) => loc.district
          ).find((dist) => dist.Name === parsedPath.district);

          updateMapCenter(
            districtCoordinates.Latitude,
            districtCoordinates.Longitude
          );
          updateZoomLevel(5.9);
          setGoBack((prevState) => ({
            ...prevState,
            district: {
              lat: districtCoordinates.Latitude,
              lng: districtCoordinates.Longitude,
              zoom: 5.9,
            },
            // state: { lat: stateCoordinates.Latitude, lng: stateCoordinates.Longitude,zoom:5.8 },
          }));

          //console.log(goBack ,"GOBACK");

          //console.log(districtCoordinates,"districtCoordinates")
        }
      }
    }
  };

  //console.log(goBack ,"GOBACKKK");

  useEffect(() => {
    getStateAndDistrictCoordinates();
  }, []);

  useEffect(() => {
    updateZoomLevelRefreshed();
  }, [zoomLevel]);

  return (
    <MapContext.Provider
      value={{
        toggleMap,
        goToIndiaMap,
        setToggleMap,
        mapCenter,
        updateMapCenter,
        updateZoomLevel,
        zoomLevel,
        oldMapCenter,
        setOldMapCenter,
        filterState,
        filteredState,
        previousLocation,
        goBack,
      }}
    >
      {/* {        //console.log(mapCenter.lat,mapCenter.lng)}    */}
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => {
  return useContext(MapContext);
};

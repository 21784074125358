import { createContext, useContext, useEffect, useState } from "react";
import { useMap } from "./MapContext.js";

const DrawerContext = createContext();

export const DrawerProvider = ({ children }) => {
  const [appState, setAppState] = useState();
  const [toggle, setToggle] = useState(false);
  const [initialValue, setInitialValue] = useState(0);
  const { goToIndiaMap } = useMap();
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState(true);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
    const jsonData = JSON.stringify(isOpen);
    localStorage.setItem("isOpen", jsonData);
    // setIsDetailActive(isOpen);
    setActive(true);
  };

  const toggleDrawerRefresh = () => {
    const jsonData = localStorage.getItem("isOpen");
    if (jsonData) {
      const parsedData = JSON.parse(jsonData);
      setOpen(parsedData);
    }
  };

  useEffect(() => {
    toggleDrawerRefresh();
  }, []);

  const updateInitialValue = (value) => {
    //console.log("changed State", value);
    setInitialValue(value);
    const stateJson = JSON.stringify(value);
    localStorage.setItem("AppState", stateJson);
    setToggle(false);
  };

  const updateAppState = (updateInitialValue) => {
    updateInitialValue(4);
  };

  useEffect(() => {
    if (toggle) {
      //console.log("called initial value",initialValue)
      updateAppState(updateInitialValue);
      // setInitialValue(4)

      // setToggle(false)
      //console.log("called initial value after",initialValue)
    }
  }, [toggle, initialValue]);
  //console.log("called initial value after after",initialValue)

  const updateInitialValueRefresh = () => {
    const stateJSON = localStorage.getItem("AppState");
    const statePath = localStorage.getItem("path");
    if (stateJSON) {
      const parsedState = JSON.parse(stateJSON);
      const parsedPath = JSON.parse(statePath);
      // setAppState(3);

      // Check if parsedState is 4 and update initialValue
      if (parsedState === 4 && !parsedPath) {
        setInitialValue(0);
        updateInitialValue(0);
        // goToIndiaMap()
      } else if (
        parsedState === 4 &&
        parsedPath &&
        parsedPath.state === "none"
      ) {
        updateInitialValue(0);
        setInitialValue(0);
      } else if (
        parsedState === 4 &&
        parsedPath &&
        parsedPath.state &&
        parsedPath.district === "none"
      ) {
        updateInitialValue(1);
        setInitialValue(1);
      } else if (
        parsedState === 4 &&
        parsedPath &&
        !parsedPath.district &&
        (parsedPath.state !== "none" || parsedPath.state !== "")
      ) {
        updateInitialValue(1);
        setInitialValue(1);
      } else if (
        parsedState === 4 &&
        parsedPath &&
        (parsedPath.district !== "none" || parsedPath.district !== "") &&
        (parsedPath.state !== "none" || parsedPath.state !== "")
      ) {
        updateInitialValue(2);
        setInitialValue(2);
      } else {
        setInitialValue(parsedState);
      }
    }

    //console.log("called");
  };

  useEffect(() => {
    updateInitialValueRefresh();
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        open,
        setOpen,
        toggleDrawer,
        updateInitialValue,
        updateAppState,
        initialValue,
        toggle,
        setToggle,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  return useContext(DrawerContext);
};

// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserDetails } from "../services/api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [st,setSt] =useState("");
  // useEffect(() => {
  //   console.log("user", user);

  //   if (user !== "") {
  //     sessionStorage.setItem('userId', user);
  //     console.log("user", user);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   const storedUserId = sessionStorage.getItem('userId');
  //   if (user === "" && storedUserId) {
  //     setUser(storedUserId);
  //   }
  // }, [user]);

  // console.log("USER", user);

  const getSessionTokenFromLs= ()=>{
    const storedUserId = sessionStorage.getItem('userId');
      if (user === "" && storedUserId) {
        console.log("called stls")

        setSt(storedUserId);
      }
  } 


  const getUserDetail = async (st)=>{
    const stWithoutTab = st.replace(/\t/g, '');
    const response = await getUserDetails(stWithoutTab);
    console.log(response?.data?.data)
    setUser(response?.data?.data)
  }



  useEffect(()=>{
getSessionTokenFromLs();
  },[])

  useEffect(()=>{
    if(st){
      console.log("called in auth",st)
      getUserDetail(st);
    }
  },[st])

  return (
    <AuthContext.Provider value={{ user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
